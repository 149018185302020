common.View.create('common.form.inputs.Date', {

    /**
     * Called when DOM is ready.
     */
    onDOMLoad() {
        this.initGui();
    },

    /**
     * Initializes the GUI.
     */
    initGui() {

        this.element

            // Single date picker
            .find('.singledate')
                .daterangepicker({
                    minDate: moment(),
                    singleDatePicker: true,
                    showDropdowns: true,
                    locale: {
                        format: 'DD-MM-YYYY',
                        firstDay: 1
                    },
                    autoUpdateInput: false
                })
                .on('apply.daterangepicker', function(ev, picker) {
                    $(this).val(picker.startDate.format('DD-MM-YYYY'));
                })
                .end()


            // Date range picker
            .find('.multidate')
                .daterangepicker({
                    minDate: moment(),
                    showDropdowns: true,
                    locale: {
                        format: 'DD-MM-YYYY'
                    },
                    autoUpdateInput: false,
                })
                .on('apply.daterangepicker', function(ev, picker) {
                    $(this).val(picker.startDate.format('DD-MM-YYYY') + ' - ' + picker.endDate.format('DD-MM-YYYY'));
                })
                .end()

            // Remove date value on cancel (or on load)
            .find('.singledate, .multidate')
                .on('cancel.daterangepicker', () => {
                    $(this).val('');
                })
                .end();

    },

});
